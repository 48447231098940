// src/components/LandslideProjectDetail/LandslideProjectDetail.js

import React, {
    useState,
    useEffect,
    useContext,
    useRef,
    useMemo,
  } from 'react';
  import { useNavigate } from 'react-router-dom';
  import * as d3 from 'd3';
  
  import api from '../../../../api';
  import { AuthContext } from '../../../auth/context/AuthContext';
  import styles from '../../common/styles/ProjectDetail.module.css';
  import { FaCalendarAlt, FaFlag } from 'react-icons/fa';
  import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
  
  import Popup from './Popup';
  import ColorBar from './ColorBar';
  import { calculateAverageTimeseries } from './utils';
  
  import useDisplacementData from '../hooks/useDisplacementData';
  import usePopupManagement from '../hooks/usePopupManagement';
  import useSelectionMode from '../hooks/useSelectionMode';
  import useCanvasDrawing from '../hooks/useCanvasDrawing';
  
  // Helper functions for cookies
  function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  
  function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  
  const LandslideProjectDetail = ({ project }) => {
    // Navigation and Auth
    const navigate = useNavigate();
    const { auth } = useContext(AuthContext);
    const projectId = project._id;
  
    // Local state
    const [error, setError] = useState("");
    const [displacementType, setDisplacementType] = useState("EW");
    const [landslideReferenceImage, setLandslideReferenceImage] = useState(null);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [imageAspectRatio, setImageAspectRatio] = useState(null);
  
    // Displacement settings: managed solely via cookies and local state
    const [minDisplacement, setMinDisplacement] = useState(-15);
    const [maxDisplacement, setMaxDisplacement] = useState(15);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [newMinDisplacement, setNewMinDisplacement] = useState(-15);
    const [newMaxDisplacement, setNewMaxDisplacement] = useState(15);
    const [saveError, setSaveError] = useState("");
  
    const [transformState, setTransformState] = useState({
      scale: 1,
      positionX: 0,
      positionY: 0,
    });
  
    // Refs for canvases and image
    const staticCanvasRef = useRef(null);
    const highlightCanvasRef = useRef(null);
    const imageRef = useRef(null);
  
    // Custom hooks
    const {
      availableDates,
      selectedDateIndex,
      displacementData,
      handleDateChange,
      error: displacementError,
    } = useDisplacementData(projectId, displacementType, auth.token);
  
    const {
      popups,
      addSinglePopup,
      addMultiPopup,
      removePopup,
      fetchTimeseriesData,
      fetchTimeseriesForMultiplePoints,
    } = usePopupManagement(
      projectId,
      displacementType,
      auth.token,
      calculateAverageTimeseries
    );
  
    const {
      selectionMode,
      toggleSelectionMode,
      polygonPoints,
      setPolygonPoints,
      currentMousePos,
      setCurrentMousePos,
      isDrawing,
      isFinalizing,
      addPolygonPoint,
    } = useSelectionMode();
  
    // Initialize displacement settings using cookies (or defaults if not present)
    useEffect(() => {
      if (auth.user && auth.user.role === "admin") {
        setIsAdmin(true);
      }
  
      const cookieMin = getCookie("minDisplacement");
      const cookieMax = getCookie("maxDisplacement");
  
      if (cookieMin !== null && cookieMax !== null) {
        const min = Number(cookieMin);
        const max = Number(cookieMax);
        setMinDisplacement(min);
        setMaxDisplacement(max);
        setNewMinDisplacement(min);
        setNewMaxDisplacement(max);
      } else {
        setMinDisplacement(-15);
        setMaxDisplacement(15);
        setNewMinDisplacement(-15);
        setNewMaxDisplacement(15);
        setCookie("minDisplacement", -15, 30);
        setCookie("maxDisplacement", 15, 30);
      }
    }, [auth.token, auth.user]);
  
    // Fetch landslide reference image
    useEffect(() => {
      const fetchLandslideData = async () => {
        try {
          const { data } = await api.get(`/landslideData/${projectId}/data`, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });
          setLandslideReferenceImage(data.referenceImage);
        } catch {
          setError("Failed to load landslide data.");
        }
      };
      fetchLandslideData();
    }, [projectId, auth.token]);
  
    useEffect(() => {
      if (displacementError) {
        setError(displacementError);
      }
    }, [displacementError]);
  
    // Toggle between displacement types (Horizontal vs. Vertical)
    const handleDisplacementTypeToggle = () => {
      setDisplacementType((prevType) => (prevType === "EW" ? "NS" : "EW"));
    };
  
    // Update image dimensions on load
    const handleImageLoad = () => {
      if (imageRef.current) {
        const { naturalWidth, naturalHeight } = imageRef.current;
        if (naturalWidth && naturalHeight) {
          setImageAspectRatio((naturalWidth * 0.7) / naturalHeight);
          setIsImageLoaded(true);
        }
      }
    };
  
    // Define a D3 color scale based on displacement settings
    const colorScale = useMemo(
      () =>
        d3
          .scaleSequential(
            d3.interpolateRgbBasis([
              "#00007f",
              "#0000ff",
              "#00ffff",
              "#00ff00",
              "#ffff00",
              "#ff0000",
              "#7f0000",
            ])
          )
          .domain([minDisplacement, maxDisplacement]),
      [minDisplacement, maxDisplacement]
    );
  
    // Canvas drawing hook; ensures canvas updates when parameters change
    const { renderCanvases } = useCanvasDrawing({
      staticCanvasRef,
      highlightCanvasRef,
      imageRef,
      isImageLoaded,
      displacementData,
      popups,
      selectionMode,
      polygonPoints,
      currentMousePos,
      isDrawing,
      isFinalizing,
      addPolygonPoint,
      addSinglePopup,
      addMultiPopup,
      fetchTimeseriesData,
      fetchTimeseriesForMultiplePoints,
      setPolygonPoints,
      setCurrentMousePos,
      setError,
      transformState,
      colorScale,
    });
  
    const imageBaseURL = process.env.REACT_APP_IMAGE_BASE_URL || "";
    const imageSrc = `${imageBaseURL}/${landslideReferenceImage}`;
  
    // Save updated displacement settings by updating cookies and local state
    const handleSaveSettings = () => {
      if (newMinDisplacement >= newMaxDisplacement) {
        setSaveError("Min displacement must be less than max displacement.");
        return;
      }
  
      setMinDisplacement(newMinDisplacement);
      setMaxDisplacement(newMaxDisplacement);
      setIsEditing(false);
      setSaveError("");
      setCookie("minDisplacement", newMinDisplacement, 30);
      setCookie("maxDisplacement", newMaxDisplacement, 30);
    };
  
    return (
      <div
        className={styles.container}
        style={imageAspectRatio ? { "--aspect-ratio": imageAspectRatio } : {}}
      >
        {/* Header */}
        <div className={styles.header}>
          <h2 className={styles.title}>{project.name}</h2>
          <button onClick={() => navigate(-1)} className={styles.backButton}>
            Back
          </button>
        </div>
  
        {/* Controls and ColorBar */}
        <div className={styles.landslideFilterContainer}>
          <div className={styles.controlRow}>
            <label className={styles.filterLabel}>Type</label>
            <button
              onClick={handleDisplacementTypeToggle}
              className={styles.filterSelect}
            >
              {displacementType === "EW" ? "Horizontal" : "Vertical"}
            </button>
          </div>
          <div className={styles.controlRow}>
            <label className={styles.filterLabel}>Mode</label>
            <button onClick={toggleSelectionMode} className={styles.filterSelect}>
              {selectionMode === "Disabled"
                ? "Enable Selection"
                : selectionMode === "Point"
                ? "Point Selection"
                : "Polygon Selection"}
            </button>
          </div>
          <div className={styles.colorBarContainer}>
            <ColorBar
              displacementType={displacementType}
              minDisplacement={minDisplacement}
              maxDisplacement={maxDisplacement}
              colorScale={colorScale}
              onDisplacementChange={(min, max) => {
                setMinDisplacement(min);
                setMaxDisplacement(max);
              }}
            />
          </div>
        </div>
  
        {/* Image Viewer */}
        <div className={styles.imageViewer}>
          <div
            className={styles.imageContainer}
            style={{
              cursor: selectionMode !== "Disabled" ? "crosshair" : "default",
            }}
          >
            <TransformWrapper
              initialScale={1}
              initialPositionX={0}
              initialPositionY={0}
              wheel={{ step: 0.1, disabled: selectionMode === "Polygon" }}
              doubleClick={{ disabled: true }}
              limitToBounds={true}
              centerZoomedOut={true}
              panning={{ disabled: selectionMode === "Polygon" }}
              zoomAnimation={{ disabled: selectionMode === "Polygon" }}
              pinch={{ disabled: selectionMode === "Polygon" }}
              onZoomStop={(ref) => {
                const { scale, positionX, positionY } = ref.state;
                setTransformState({ scale, positionX, positionY });
              }}
              onPanningStop={(ref) => {
                const { scale, positionX, positionY } = ref.state;
                setTransformState({ scale, positionX, positionY });
              }}
            >
              {({ zoomIn, zoomOut, resetTransform }) => (
                <>
                  <div className={styles.controls}>
                    <button
                      onClick={zoomIn}
                      className={styles.controlButton}
                      aria-label="Zoom In"
                    >
                      +
                    </button>
                    <button
                      onClick={zoomOut}
                      className={styles.controlButton}
                      aria-label="Zoom Out"
                    >
                      -
                    </button>
                    <button
                      onClick={resetTransform}
                      className={styles.controlButton}
                      aria-label="Reset Zoom"
                    >
                      Reset
                    </button>
                  </div>
                  <TransformComponent>
                    <div style={{ position: "relative" }}>
                      <img
                        ref={imageRef}
                        src={imageSrc}
                        alt="Reference"
                        className={styles.displayedImage}
                        loading="lazy"
                        onLoad={handleImageLoad}
                        style={{ width: "100%", height: "auto" }}
                      />
                      {renderCanvases()}
                    </div>
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          </div>
  
          {/* Slider */}
          <div className={styles.sliderContainer}>
            {availableDates.length > 0 && (
              <input
                type="range"
                min="0"
                max={availableDates.length - 1}
                value={selectedDateIndex}
                onChange={handleDateChange}
                className={styles.slider}
              />
            )}
            <div className={styles.timestampContainer}>
              <div className={styles.timestampBox}>
                <div className={styles.iconLabelRow}>
                  <FaCalendarAlt className={styles.icon} />
                  <span className={styles.timestampLabel}>From:</span>
                </div>
                <div className={styles.timestampValue}>
                  {availableDates.length > 0
                    ? new Date(availableDates[0]).toLocaleString()
                    : "N/A"}
                </div>
              </div>
              <div className={styles.timestampBox}>
                <div className={styles.iconLabelRow}>
                  <FaFlag className={styles.icon} />
                  <span className={styles.timestampLabel}>To:</span>
                </div>
                <div className={styles.timestampValue}>
                  {availableDates[selectedDateIndex]
                    ? new Date(availableDates[selectedDateIndex]).toLocaleString()
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* Popup Overlay */}
        <div className={styles.popupOverlay}>
          {popups.map((popup) => (
            <Popup key={popup.id} popup={popup} removePopup={removePopup} />
          ))}
        </div>
  
        {/* Display error messages if any */}
        {error && (
          <div className={styles.error}>
            <p>{error}</p>
          </div>
        )}
      </div>
    );
  };
  
  export default LandslideProjectDetail;
  