import React, { useState, useEffect } from 'react';
import styles from '../../common/styles/ProjectDetail.module.css';

const ColorBar = ({ 
    displacementType, 
    minDisplacement, 
    maxDisplacement, 
    colorScale,
    onDisplacementChange 
}) => {
    const [localMin, setLocalMin] = useState(minDisplacement);
    const [localMax, setLocalMax] = useState(maxDisplacement);
    const [error, setError] = useState('');

    // Update local state when props change
    useEffect(() => {
        setLocalMin(minDisplacement);
        setLocalMax(maxDisplacement);
    }, [minDisplacement, maxDisplacement]);

    const numStops = 100;
    const stops = Array.from({ length: numStops }, (_, i) =>
        localMin + (i / (numStops - 1)) * (localMax - localMin)
    );
    const gradientColors = stops.map(value => colorScale(value));

    const gradientStyle = {
        background: `linear-gradient(to right, ${gradientColors.join(', ')})`,
        height: '20px',
        width: '200px',
        position: 'relative',
        border: '1px solid #000',
    };

    const handleInputChange = (value, isMin) => {
        // Allow empty value
        if (value === '') {
            if (isMin) setLocalMin('');
            else setLocalMax('');
            return;
        }
        
        const numValue = parseFloat(value);
        if (isNaN(numValue)) return;
        if (isMin) {
            if (numValue >= localMax) {
                setError('Min must be less than max');
                return;
            }
            setLocalMin(numValue);
            onDisplacementChange(numValue, localMax);
        } else {
            if (numValue <= localMin) {
                setError('Max must be greater than min');
                return;
            }
            setLocalMax(numValue);
            onDisplacementChange(localMin, numValue);
        }
        setError('');
    };

    return (
        <div className={styles.colorBarContainer}>
            <div className={styles.colorBarTitle}>
                Displacement [mm]
            </div>
            <div className={styles.colorBarGradient} style={gradientStyle} />
            <div className={styles.colorBarInputs}>
                <input
                    type="number"
                    value={localMin}
                    onChange={(e) => handleInputChange(e.target.value, true)}
                    className={styles.displacementInput}
                    step="0.1"
                    min={-999999}
                    max={localMax}
                />
                <input
                    type="number"
                    value={localMax}
                    onChange={(e) => handleInputChange(e.target.value, false)}
                    className={styles.displacementInput}
                    step="0.1"
                    min={localMin}
                    max={999999}
                />
            </div>
            {error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

export default ColorBar;